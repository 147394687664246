import {createCRUDDuck} from "@sha/fsa";
import {ISOState} from "../../../ISOState";
import {Bootstrap} from "../../bootstrapDuck";
import type {Schema} from "./UserSchema";
import chroma from 'chroma-js'
import {FrontState} from "front/src/store/frontReducer";

/**
 * TODO ACL rules are enougth for the first stage
 */

/**
 * 1. Строитель
 *
 * 2. Снабженец
 *
 * 3. Технадзор
 *
 * 4. Фасадчик
 *
 * 5. Архитектор
 */

enum RoleType {
    // no-no, ACL rules are enougth for the first stage
}


export type UserVO = Schema


const duck = createCRUDDuck<UserVO, 'userId'>('users', 'userId')

const selectUserByEmail = (email: string) => (state: ISOState) => {

    const users = state.app.bootstrap.users

    if (!email)
        return undefined

    const userByEmail = users.find(user =>
            user.email && (
                user.email.toLowerCase() === email.toLowerCase()
            )
    )
    return userByEmail
}

var uiAvatarColors = [
    '#775DD0',
    '#FF9800',
    '#A5978B',
    '#FD6A6A',
    '#69D2E7',
    '#C5D86D',
    '#E2C044',
    '#C4BBAF',
    '#00B1f2',
    '#8b71e4',
    '#ffac14',
    '#b9ab9f',
    '#ff7e7e',
    '#7de6fb',
    '#d9ec81',
    '#f6d458',
    '#d8cfc3',
    '#14c5ff',
]
let fontColors = ['#f5f6f9', '#383a3e']
var generateGravatar = (index, n, s) => {
    const num = index % uiAvatarColors.length
    const bgColor = uiAvatarColors[num]

    const c1 = chroma.contrast(bgColor, '#f5f6f9')
    const c2 = chroma.contrast(bgColor, '#383a3e')

    const color = chroma.contrast(bgColor, '#f5f6f9') > chroma.contrast(bgColor, '#383a3e')
        ? 'f5f6f9' : '383a3e'
    return `https://ui-avatars.com/api/?background=${bgColor.substring(1)}&font-size=${0.4}&color=${color}&format=svg&name=${n}+${s}`
}



export const usersCRUD = {
    ...duck,
    actions: {
        ...duck.actions,
        usersListUpdated: duck.factory<UserVO[]>('usersListUpdated')
    },
    selectUserByEmail,

    selectUserByCredentials: ({email, password}) => (state: ISOState) => {
        const user = selectUserByEmail(email)(state)
        return (user && user.password === password)
            ? user
            : undefined
    },
    selectAvatar: (userId: string) => (state: FrontState) => {
        const user = duck.selectById(userId)(state)
        const num = Number(userId)
        return generateGravatar(num, user.firstName, user.lastName)
    },

    selectBootstrapFoUser: (userId: string) => (state: ISOState): Partial<Bootstrap> => {
        const boot = state.app.bootstrap
        const user = usersCRUD.selectById(userId)(state)
        const projects = state.app.bootstrap.projects.filter((p,index) => {
            if(!p)
                console.error("Project "+ index+"  = " + undefined)
            return p && user.projectIds.includes(p.projectId)
        })
        return {
            ...boot,

            projects: projects.map( ({stones, comments, events, ...p})=>({
                ...p,
                stones:[],
                comments: [],
                events: []
            })  )
            // users: boot.users.filter(u => u.userId === userId),
        }
    },

}

export default usersCRUD
