import React, { useState } from "react";
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import * as R from "ramda";
import { nav } from "../nav";
import * as icons from "ionicons/icons";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import projectsCURD, {
  ProjectVO,
} from "iso/src/store/bootstrap/repos/projectsCURD";
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
  IonToolbar,
} from "@ionic/react";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import { useHistory } from "react-router";
import IonCurrentRoute from "../controls/IonCurrentRoute";
import WsContent from "../controls/WsContent";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useStatuses from "../../../hooks/useStatuses";
import { uiDuck } from "../../../store/ducks/uiDuck";
import BackToProjectButton from "../controls/BackToProjectButton";
import useFetch from "../../../hooks/useFetch";
import StackedChart from "./StackedChart";
import moment from "moment";
import ChartWindows from "./ChartWindows";

const getGroup = (projectGroup: string): string => {
  const groups = {
    facade: 'Группа 1',
    oranges: 'Группа 2'
  }

  return groups[projectGroup]
}

const renderChart = (projectGroup, projects) => {
  switch (projectGroup) {
    case 'facade':
      return <StackedChart projects={projects}/>
    case 'oranges':
      return <ChartWindows projects={projects}/>
    default:
      return <StackedChart projects={projects}/>
  }
}

export default ({projectGroup}) => {
  const { currentUser, availableRoles } = useCurrentUser();

  const { data, error } = useFetch(
    `/api/v1/charts/${currentUser.userId}/${getGroup(projectGroup)}`,
    { method: "POST" }
  );
  const history = useHistory();
  const dispatch = useFrontDispatch();
  const projects: ProjectVO[] = useFrontSelector(projectsCURD.selectList); //.filter(p => p.projectId !== '0')

  const settings = useFrontSelector(settingsDuck.selectSettings);
  const [projectId, setProjectId] = useState(
    R.last(projects).projectId as string
  );
  const { getStatusById } = useStatuses();

  const ui = useFrontSelector(uiDuck.selectUI);
  const roleId = ui.roleId;
  console.log("UI", ui);
  const setRoleId = (id) => dispatch(uiDuck.actions.setRole(id));

  const role = settings.roles.find((r) => r.roleId === roleId);
  //  console.log('Selected roleId = ' + roleId + ', roles list ' + settings.roles, currentUser)

  const onSubmit = async () => {
    dispatch(uiDuck.actions.setSelection([]));
    const url = nav.project({ projectId });
    history.push(url);
  };

  const onBack = () => {
    const url = nav.access({});
    history.push(url);
  };
  //  console.log('roleId', roleId, role)

  const renderHeaderTitle = () => {
    switch (projectGroup) {
      case 'facade':
        return 'Аналитика - Фасадные работы'
      case 'oranges':
        return 'Аналитика - Окна'
      default:
        return 'Аналитика'
    }
  }

  const [open, setOpen] = useState(false);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onBack}>
              <IonIcon slot="start" icon={icons.chevronBackOutline}></IonIcon>
              Главная
            </IonButton>
          </IonButtons>
          <IonCurrentRoute>{renderHeaderTitle()}</IonCurrentRoute>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={!data} message={"Загрузка проектов"}></IonLoading>
        {data && [
          <IonItem>
            <IonText>
              <h2>
                Фактический статус на сегодня: {moment().format("DD.MM.YYYY")}
              </h2>
            </IonText>
          </IonItem>,
          renderChart(projectGroup, data.projects)
        ]}
      </IonContent>
    </IonPage>
  );
};
