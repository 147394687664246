import {IonItem, IonLabel} from '@ionic/react'
import WSSelect from './WSSelect'
import React from 'react'
import useFrontSelector from '../../../hooks/common/useFrontSelector'
import {uiDuck} from '../../../store/ducks/uiDuck'
import useFrontDispatch from '../../../hooks/common/useFrontDispatch'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useRole from '../../../hooks/useRole'

export default ({layer}:{layer?: string}) => {
    const {currentUser, availableRoles} = useCurrentUser()
    const layerRoleIds = (layer ? availableRoles.filter(r => r.layers.includes(layer)): availableRoles).map( r => r.roleId)
    const enabledRoleIds = currentUser.roleIds.filter(id => layerRoleIds.includes(id))
    const dispatch = useFrontDispatch()
    const ui = useFrontSelector(uiDuck.selectUI)
    const roleId = ui.roleId

    const getRoleById = (id ) => availableRoles.find(r => r.roleId == id)
     const {currentRole} = useRole()
    console.log('UI', ui)
    const setRoleId = (id) => {

        dispatch(uiDuck.actions.setSelection([]))
        dispatch(uiDuck.actions.setRole(id))
    }
    return  <IonItem lines={'full'}>
        <IonLabel position={"fixed"}>Роль</IonLabel>
        <WSSelect
            type={'alert'}
            interfaceOptions={{size: 'cover'}}
            placeholder={'Укажите роль'}
            value={enabledRoleIds.includes(roleId) ? String(roleId): undefined}
            onValueChange={v => setRoleId(Number(v))}
            options={enabledRoleIds.map(id => {
                const r = getRoleById(id)
                return {
                value: String(r.roleId),
                label: r.name,
            }})}
        /> </IonItem>
}