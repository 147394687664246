import React, {useState} from 'react'
import {uiDuck} from "../../../store/ducks/uiDuck";
import {appStorage} from "iso";
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import './Login.scss';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    useIonAlert,
    useIonLoading
} from "@ionic/react";
import WsContent from "../controls/WsContent";
import {useStore} from "react-redux";
import {loginSaga} from "../../../sagas/loginSaga";
import {useHistory} from "react-router";
import getRestApi from "iso/src/getRestApi";
import Logo from '../../../theme/Logo'

export default () => {


    const dispatch = useFrontDispatch()

    const history = useHistory()
    const store = useStore()
    const [visible, setVisible] = useState(false)
    const [presetAlert, dismissAlert] = useIonAlert()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)
    const [presentLoading, dismissLoading] = useIonLoading()


    const onSubmit = async (e) => {
        dismissLoading()
        e.preventDefault()
        if (!email) {
            presetAlert('Укажите почту')
            return
        }
        if (!password) {
            presetAlert('Пароль не указан')
            return
        }
        const params = {password, email, remember, pathname: window.location.pathname}
        const body = JSON.stringify(params)
        await presentLoading('Авторизация')
        try {
            const api = await getRestApi()
            const response = await api.login(params)
            if (response) {


                if (params.remember) {
                    appStorage.setItem('credentials', params)
                } else {
                    appStorage.removeItem('credentials')
                }
              //  store.rusSaga(loginSaga, history)
                dispatch(uiDuck.actions.loginRequested(params))
                await dismissLoading()
            } else {

                const data = await response.json().catch(err => undefined)

                dispatch(uiDuck.actions.unbusy('Login'))
                await dismissLoading()
              await  presetAlert('Неверный логин/пароль')

            }
        } catch (e) {
            dispatch(uiDuck.actions.unbusy('Login'))
            await dismissLoading()
            await presetAlert('Не удалось авторизоваться')
        }
    }
    return (
        <IonPage className={'ws-page'}>
            <IonContent>
                <WsContent>
                    <div className="ws-central login-logo ">
                        <Logo />
                    </div>
                    <IonCard className={'ws-central'}>
                        <IonCardHeader>
                            <IonCardSubtitle>Авторизация</IonCardSubtitle>
                            <IonCardTitle>Строй-Мониторинг</IonCardTitle>
                        </IonCardHeader>
                        <form noValidate onSubmit={onSubmit}>
                            <IonCardContent>
                                <IonItem lines={'full'}>
                                    <IonLabel position={'stacked'} color="primary">Почта</IonLabel>
                                    <IonInput name="email" type="email" placeholder={'name@email.ru'}
                                              inputmode={'email'} value={email} spellCheck={false} autocapitalize="off"
                                              onIonChange={e => setEmail(e.detail.value!)}

                                              required>
                                    </IonInput>
                                </IonItem>

                                {/*formSubmitted && usernameError && <IonText color="danger">
                                <p className="ion-padding-start">
                                    Username is required
                                </p>
                            </IonText>*/
                                }

                                <IonItem lines={'full'}>
                                    <IonLabel position="floating" color="primary">Пароль</IonLabel>
                                    <IonInput name="password" autocomplete={'current-password'} type="password"
                                              value={password} placeholder={'password'}
                                              onIonChange={e => setPassword(e.detail.value!)} required>
                                    </IonInput>
                                </IonItem>

                                {/*formSubmitted && passwordError && <IonText color="danger">
                                    <p className="ion-padding-start">
                                        Password is required
                                    </p>
                                </IonText>*/}
                                <IonItem lines={'none'}>
                                    <IonLabel position={'fixed'}>
                                        Запомнить
                                    </IonLabel>
                                    <IonCheckbox
                                        slot={'end'}
                                        checked={remember}
                                        onIonChange={e => setRemember(e.detail.checked)}
                                    >
                                    </IonCheckbox>
                                </IonItem>
                                <IonButton type="submit" color={'success'} expand={'full'}>Login</IonButton>
                            </IonCardContent>
                        </form>
                    </IonCard>


                    {/*
</IonContent>
            <IonContent className={'ion-align-items-center'}>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol>
                <IonList>
                   <IonItem>
                        <IonLabel position="stacked">Логин</IonLabel>
                        <IonInput
                            value={email}
                            type={'email'}
                            inputmode={'email'}
                            placeholder={'name@email.ru'}
                            onIonChange={e => setEmail(e.detail.value!)}
                            clearInput>
                        </IonInput>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Пароль</IonLabel>
                        <IonInput
                            value={password}
                            placeholder={'password'}
                            type={'password'}
                            onIonChange={e => setPassword(e.detail.value!)}
                            clearInput>
                        </IonInput>
                    </IonItem>


                        <IonButton expand={'full'} onClick={onSubmit} disabled={!password || !email}>Авторизоваться</IonButton>

                </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>*/}
                </WsContent>
            </IonContent>
        </IonPage>
    )
}
