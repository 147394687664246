import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import * as Icons from "ionicons/icons";
import * as React from "react";
import {ExtractProps} from "@sha/react-fp";
import {connect} from "react-redux";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import {StoneStatus} from "iso/src/store/bootstrap/StatusesMatrix";

type IonItemProps = ExtractProps<typeof IonItem>



export type StatusItemProps = {
    statusId: number
    children?: React.ReactNode | undefined,
    selected?: boolean
    emptyLabel?: string
    status?: StoneStatus
    button?: boolean
    currentPrecent?: number
    progressBar?: React.ReactNode

} & IonItemProps

const formatPercentText = n => {
    if(isNaN(n))
        return '??'
    return (n < 0
        ? '0' + n.toFixed(2)
        : n < 10
            ? '0' + n.toFixed(2)
            : n.toFixed(2)) + '%'
}


const StatusItem = connect(
        (state, ownProps) => ({
            status: settingsDuck.selectSettings(state).statuses.find(s => s.statusId === ownProps.statusId)
        }),
    )(({children, selected,button, currentPrecent,statusId, emptyLabel,status, progressBar, lines,onClick}: StatusItemProps) => {

    //const itemRef = React.useRef<HTMLIonItemElement>(null)
    //const backgroundColor = chroma(status.color).darken(-2).hex()
    //useStyleImportant(itemRef, 'backgroundColor',backgroundColor, true)

    return statusId ?
            <IonItem
                button={button}
                lines={lines}
                onClick={onClick}

            >
                <StatusColorIcon color={status.color}/>
                <IonLabel>
                    <h4>{status.name }</h4>
                    {progressBar}
                </IonLabel>
                {
                    children
                }
                {
                    selected &&
                    <IonIcon slot={'end'} icon={Icons.checkmark}></IonIcon>
                }
            </IonItem>
        : <StatusItemPlaceholder onClick={onClick} button={button} emptyLabel={emptyLabel} children={children}/>
})



const StatusItemPlaceholder = React.memo(({emptyLabel, button, children, onClick}) => {
    return <IonItem onClick={onClick} button={button}>
        <IonIcon size={'small'}
                 icon={Icons.ellipseOutline}
                 color={'dark'}
                 style={{marginRight: '8px'}}
        >
        </IonIcon>
        <IonLabel>{emptyLabel}</IonLabel>{children}
    </IonItem>
} )


export const StatusColorIcon = React.memo(({color}:{color?: string})=> {
    return  <IonIcon size={'small'}
                    icon={Icons.ellipse}
                    style={{marginRight: '8px', color: color}}
            >
            </IonIcon>
})




export default StatusItem