export const doubles = [
    "3644",
    //"5860",
    "6598",
    "11182",
    "11690",
    "12186",
    "12187",
    "12190",
    "12261",
    "12263",
    "12264",
    "12265",
    "12353",
    "12355",
    "12357",
    "12359",
    "12361",
    "12363",
    "12365",
    "12367",
    "12369",
    "12371",
    "12373",
    "12375",
    "12377",
    "12379",
    "12381",
    "12383",
    "12385",
    "12387",
    "12389",
    "12391",
    "12393",
    "12395",
    "12397",
    "12399",
    "12401",
    "12403",
    "12405",
    "12407",
    "12409",
    "12411",
    "12413",
    "12415",
    "12417",
    "12419",
    "12421",
    "12423",
    "12425",
    "12427",
    "12429",
    "12431",
    "12433",
    "12435",
    "12437",
    "12439",
    "12441",
    "12443",
    "12445",
    "12447",
    "12449",
    "12451",
    "12453",
    "12455",
    "12457",
    "12459",
    "12461",
    "12463",
    "12465",
    "12467",
    "12469",
    "12471",
    "12473",
    "12475",
    "12477",
    "12479",
    "12481",
    "12483",
    "12485",
    "12487",
    "12489",
    "12491",
    "12493",
    "12495",
    "12497",
    "12499",
    "12501",
    "12503",
    "12505",
    "12507",
    "12509",
    "12511",
    "12513",
    "12515",
    "12517",
    "12519",
    "12521",
    "12523",
    "12525",
    "12527",
    "12529",
    "12531",
    "12533",
    "12535",
    "12537"
]

export const needToSkip = id => doubles.includes(id) && id !== doubles[1]