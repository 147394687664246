import {IonPage, IonRouterOutlet} from "@ionic/react";
import ProjectMenu from "./scene/ProjectMenu";
import React from "react";

import {nav} from "./nav";
import WSIonRoute from "./controls/WSIonRoute";
import MapPage from "./scene/MapPage";

const ProjectPage = ({projectId, ...props}) => {
    //console.log('ProjectPage', projectId, props.location.pathname, props)

    return (
        <IonPage>

            <ProjectMenu projectId={projectId}/>{/*/>*/}
            <IonRouterOutlet animated={false} id={'main'}>
                <WSIonRoute
                    exact={true}

                    render={props => {

                        return <MapPage {...props} {...props.match.params}/>
                    }}
                    path={'/app/in/projects/:projectId'}
                />

            </IonRouterOutlet>


        </IonPage>

    )
}


export default React.memo(ProjectPage)
