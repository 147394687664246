export const toPercent = (total: number, current: number) => {
  const part = current / total;
  const ceil = Math.ceil(part * 10000);
  return ceil / 100;
};

export const percentFormatter = (value) => Number(value).toFixed(2) + " %";

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Sector = "bottom" | "center" | "top";

export const calculateMinWidthContainer = (barItemsCount: number) => {
  if (barItemsCount <= 3) {
    return 360;
  }

  switch (barItemsCount) {
    case 4:
      return 450;
    case 5:
      return 510;
    case 6:
      return 570;
    case 7:
      return 630;
    case 8:
      return 690;
    case 9:
      return 750;
    case 10:
      return 810;
    case 11:
      return 870;
    case 12:
      return 930;
    default:
      return 1000;
  }
};