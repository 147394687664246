import React, {useRef, useState} from 'react';
import {
    IonAvatar,
    IonButton,
    IonContent,
    IonHeader,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react';

function Example() {
    const modal = useRef<HTMLIonModalElement>(null);
    const [count, setCount] = useState(0);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>App</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <p>You can interact with the +/- buttons until the sheet is fully expanded.</p>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <IonButton onClick={() => setCount(count - 1)}>-</IonButton>
                    <p>{count}</p>
                    <IonButton onClick={() => setCount(count + 1)}>+</IonButton>
                </div>
                <IonModal
                    ref={modal}
                    trigger="open-modal"
                    isOpen={true}
                    initialBreakpoint={0.25}
                    breakpoints={[0.25, 0.5, 0.75]}
                    backdropDismiss={false}
                    backdropBreakpoint={0.5}
                >
                    <IonContent className="ion-padding">
                        <IonSearchbar onClick={() => modal.current?.setCurrentBreakpoint(0.75)}
                                      placeholder="Search"></IonSearchbar>
                        <IonList>
                            <IonItem>
                                <IonAvatar slot="start">
                                    <IonImg src="https://i.pravatar.cc/300?u=b"/>
                                </IonAvatar>
                                <IonLabel>
                                    <h2>Connor Smith</h2>
                                    <p>Sales Rep</p>
                                </IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonAvatar slot="start">
                                    <IonImg src="https://i.pravatar.cc/300?u=a"/>
                                </IonAvatar>
                                <IonLabel>
                                    <h2>Daniel Smith</h2>
                                    <p>Product Designer</p>
                                </IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonAvatar slot="start">
                                    <IonImg src="https://i.pravatar.cc/300?u=d"/>
                                </IonAvatar>
                                <IonLabel>
                                    <h2>Greg Smith</h2>
                                    <p>Director of Operations</p>
                                </IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonAvatar slot="start">
                                    <IonImg src="https://i.pravatar.cc/300?u=e"/>
                                </IonAvatar>
                                <IonLabel>
                                    <h2>Zoey Smith</h2>
                                    <p>CEO</p>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
}

export default Example;
