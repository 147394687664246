import * as fsa from '@sha/fsa'
import {usersCRUD} from './bootstrap/index'
import {combineReducers} from 'redux'
import {put, takeLatest} from 'typed-redux-saga'
import sessionsDuck from './bootstrap/repos/sessionsCRUD'
import sessionsCRUD from './bootstrap/repos/sessionsCRUD'
import configDuck from './bootstrap/configDuck'
import projectsCURD from "./bootstrap/repos/projectsCURD";
import projectsEventsCRUD from "./bootstrap/projectsEventsCRUD";
import settingsDuck from "./bootstrap/settingsDuck";
import sheetsDuck from "./bootstrap/sheetsDuck";

const factory = fsa.actionCreatorFactory('bootstrap')

const actions = {
    fetchBootstrap: factory.async<Bootstrap>('fetchBootstrap'),
    serverStateBootstraped: factory<Bootstrap>(
        'serverStateBootstraped'
    )
}

const reducer = combineReducers({
    config: configDuck.reducer,
    users: usersCRUD.reducer,
    sessions: sessionsDuck.reducer,
    projects: projectsCURD.reducer,
    events: projectsEventsCRUD.reducer,
    settings: settingsDuck.reducer,
    sheets: sheetsDuck.reducer,
})

export type Bootstrap = ReturnType<typeof reducer>

const bootstrapDucks = [
    projectsCURD,
    sessionsCRUD,
    usersCRUD,
    configDuck,
]


export function* bootstrapSetupSaga() {
    yield* takeLatest(bootstrapDuck.actions.fetchBootstrap.done.type, function* (action) {

        for (let i = 0; i < bootstrapDucks.length; i++) {
            const duck = bootstrapDucks[i]
            if (duck && duck.actions && duck.actions.reset) {
                const act = duck.actions.reset(action.payload.bootstrap[duck.factoryPrefix] || [])

                yield* put(act)
            }
        }

    })
}

export const bootstrapDuck = {
    reducer,
    factory,
    actions,
    selectBootstrap: (state) =>
        state.app.bootstrap
}
