import React, {useCallback} from 'react';
import moment from "moment";
import {
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonText,
    useIonAlert,
} from '@ionic/react'
import projectsCURD, {CommentVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import {usersCRUD, UserVO} from "iso/src/store/bootstrap/repos/usersCRUD";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {selectCurrentUser} from "../../../store/frontReducer";
import {pencil, trash} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import UserAvatar from "../controls/UserAvatar";

export type IDHandler<T = boolean> = VOHandler<string, T>
export type VOHandler<V = CommentVO, T = boolean> = (item: V) => Promise<T> | T
type CommentKeys = {
    commentId: string
}

type CommentViewProps = {
    comment: CommentVO
    onSelect?: VOHandler<CommentVO>
    onReply?: IDHandler
    onRemove?: IDHandler
    onEdit?: IDHandler
}

export default ({onSelect, comment}: CommentViewProps) => {
    const dispatch = useDispatch()
    const currentUser: UserVO = useFrontSelector(selectCurrentUser)
    const commentUser: UserVO = useFrontSelector(usersCRUD.selectById(comment.userId))

    const datetime = moment(comment.createdAt).format('YYYY/MM/DD hh:mm')
    const author = <a>{commentUser.firstName} {commentUser.lastName} </a>
    const avatarURL = useSelector(usersCRUD.selectAvatar(commentUser.userId))
    const text = comment.text
    const onClick = useCallback(() => onSelect ? onSelect(comment) : undefined, [comment, onSelect])
    const [presentAlert] = useIonAlert();
    const onDeleteClick = () => {
        presentAlert('Удалить комментарий',[
            {
                text: 'Да',
                handler:onDelete
            },
            {
                text:'Нет',

            }
        ])


    }

    const onDelete = () => {

        dispatch(projectsCURD.actions.commentRemoved({commentId:comment.commentId, projectId: comment.projectId}))
    }
    const itemProps = {
        button: !!onSelect,
        onClick: onSelect ? onClick : undefined
    }


    return <IonItemSliding>
        <IonItem {...itemProps}>
            <UserAvatar userId={commentUser.userId}/>
            <IonLabel style={{whiteSpace: 'break-spaces'}}>
                <h4>{author}</h4>
                <p>{text}</p>
            </IonLabel>
            <IonText slot="end">{datetime}</IonText>
        </IonItem>
        <IonItemOptions side="end">
            <IonItemOption color="danger" onClick={onDeleteClick}>
                <IonIcon slot="icon-only" icon={trash}/>
            </IonItemOption>
            <IonItemOption>
                <IonIcon slot="icon-only" icon={pencil}/>
            </IonItemOption>
        </IonItemOptions>
    </IonItemSliding>

    /*  <IonCard style={ {border:"solid gray"}}>
        <IonCardHeader>
            <IonLabel className="ion-text-bold">
                <h4 style={ {color, fontWeight:"bold"}}>
                    { sender } { currentUser===sender && " (You) "}:
                </h4>
            </IonLabel>
        </IonCardHeader>
        <IonCardContent>
            <IonText>
                { message }
            </IonText>
        </IonCardContent>
    </IonCard>*/
}
