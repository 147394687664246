import React, {useCallback, useState} from 'react'
import * as Icons from 'ionicons/icons';
import StatusSelector from "../../list-statuses/StatusSelector";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {useDispatch} from "react-redux";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {nav} from "../nav";
import {useHistory} from "react-router";
import {
    IonButton,
    IonButtons, IonCol,
    IonFooter, IonGrid,
    IonIcon, IonInput,
    IonItem,
    IonLabel, IonRow, IonTitle,
    IonToolbar,
    useIonViewDidEnter
} from "@ionic/react";
import {useProject} from "../../../hooks/useProject";
import useStatuses from "../../../hooks/useStatuses";
import useCurrentUser from "../../../hooks/useCurrentUser";
import projectsCURD, {
    attrsToBaseName,
    parseStoneAttrs,
    StoneAttrs,
    StonePatch,
    StoneVO
} from "iso/src/store/bootstrap/repos/projectsCURD";
import useStones from "../../../hooks/useStones";
import useViewer from "./tools/useViewer";
import useInteractiveStones, {ColorMapper} from "./tools/useInteractiveStones";
import {equals, reject} from "ramda";

import Color = types.Color;


const ToolRename = (/*{selectedStoneIds}:{selectedStoneIds: string[]}*/) => {
    const viewer = useViewer()
    const dispatch = useDispatch()

//    console.log('ToolSingle', viewer)
    const project = useProject()
    const {projectId} = project

    const {getStatusById} = useStatuses()
    const {currentRole} = useCurrentUser()

    const onStoneClick = (id) => {
        const selectedStoneIds = window.store.getState().ui.selection.ids
        if (id === undefined)
            return;

        console.log('Click stone '+id)
        console.log('Current selected stones '+selectedStoneIds)
        if(selectedStoneIds.includes(id)) {
            console.log('deselect stone '+id)
            setSelectedStoneIds(reject(equals(id), selectedStoneIds))
            return
        }

        setSelectedStoneIds([id, ...selectedStoneIds])
        console.log('Stone added ', id)

    }

    const colorMapper: ColorMapper = useCallback((stone) => {
        const attrs = parseStoneAttrs(stone)
        if(attrs.completedAttrs === 0)
            return '#A5A5A5'
        else if(attrs.completedAttrs == 6)
            return '#00B050'
        return '#FF9900'
    },[])
    const [selectedStoneIds, setSelectedStoneIds] = useInteractiveStones({onStoneClick,colorMapper})

    /* useIonViewDidEnter(() =>{
         console.log('ToolMultipleDidEnter')
         if(window['focus']) {
             const ids = window['focus']
             window['focus'] = undefined
             dispatch(uiDuck.actions.focusToList(ids))
             setSelectedStoneIds(ids)
         }
     }, [])*/



    const selectedStones = []
    const values = {
        K: [] ,
        type:[],
        mark: [],
        F: [],
        level: [],
        size: []
    }
    for(let i = 0; i < selectedStoneIds.length;i++) {
        const stone = project.stones[selectedStoneIds[i]]
        selectedStones.push(stone)
        const attrs = parseStoneAttrs(stone)

        if( values.K.indexOf(attrs.K) === -1)
            values.K.push(attrs.K)

        if( values.level.indexOf(attrs.level) === -1)
            values.level.push(attrs.level)
        if(values.size.indexOf(attrs.size) === -1)
            values.size.push(attrs.size)
        if( values.mark.indexOf(attrs.mark) === -1)
            values.mark.push(attrs.mark)
        if( values.F.indexOf(attrs.F) === -1)
            values.F.push(attrs.F)
        if(values.type.indexOf(attrs.type) === -1)
            values.type.push(attrs.type)
    }


    console.log('SELECTED STONES ', selectedStones)

    const onAttrChange = (attr: keyof StoneAttrs) => (value: string) => {
        console.log('Set attr '+attr+' to '+value)
        const updates: { id, baseName }[] = selectedStones.map( (stone: StoneVO) =>{
            const stoneAttrs = parseStoneAttrs(stone)
            stoneAttrs[attr] = value
            const baseName = attrsToBaseName(stoneAttrs)
            console.log(stone.id,name, stoneAttrs)
            return {
                id: stone.id,
                    baseName,
            }
        })

        const action = projectsCURD.actions.stonesAttributesUpdated({
            updates,
            projectId,
        })
        action.meta.persistent = true
        dispatch(action)
        //setTimeout(setSelectedStoneIds,100,[])
    }


    console.log('VALUES', values)
    const renderAttr = (prop: keyof StoneAttrs) => {
        const vals = values[prop]
        let color = undefined as string
        let inputProps = {
            placeholder:'Пусто',
            value: undefined
        }
        if(vals.length === 1  && (vals[0] === undefined || vals[0].length === 0 || vals[0].trim()==='')) {

        } else if(vals.length === 1) {

            inputProps.value = vals[0]
            color = 'success'
        } else {
            color = 'warning'
            inputProps.placeholder = 'Разные '+vals.length+': ' +vals.map(v => (v===undefined || v==='*')? 'Пусто':v) .join(', ')
            inputProps.value = undefined
        }


        return  <IonCol key={prop}>
            <IonItem color={color}>
                <IonLabel>{prop}</IonLabel>
                <IonInput {...inputProps} maxlength={prop==='type' ? 1 : 8} onIonInput={ e => {
                    console.log('onIonINPUT',prop,e)
                    window['e']=e
                    onAttrChange(prop)(e.target.value)
                }
                }></IonInput>
            </IonItem>
        </IonCol>
    }
    const stoneId  = selectedStoneIds[0]
    if(stoneId) {
        return <IonFooter>

            <IonToolbar  >

                <IonGrid>
                    <IonRow>
                        {renderAttr('K')}

                        {renderAttr('type')}

                        {renderAttr('mark')}
                    </IonRow>
                    <IonRow>
                        {renderAttr('F')}

                        {renderAttr('level')}

                        {renderAttr('size')}
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter>

    }


    const stone = project.stones.find(s => s.id === stoneId)



    return <IonFooter translucent={true}>
        <IonToolbar>
            <IonTitle>Начните выбор элементов</IonTitle>
        </IonToolbar>
    </IonFooter>


}

export default React.memo(ToolRename)
