import * as THREE from "three";
import chroma from "chroma-js"
import {calendarNumber} from "ionicons/icons";
import {Vector3} from "three";

export const getColorAnimTint = (baseColorHEX: string, step: number, duration: number = 128) => {
    const grade = step < duration /2? step : duration - step

    const ampl = duration / 4
    const darkenPerStep = 5 / ampl
    const loc = grade - ampl
    const k = loc * darkenPerStep
    // console.log(step, darkenPerStep, grade, loc, k.toFixed(3), )
    const color = chroma(baseColorHEX).darken(Math.abs(k)-2).hex();
    return color
}

export const getDisabledTint = (baseColorHEX: string) => {
    const color = chroma(baseColorHEX).darken(0-3).hex();
    return color
}
window['getColorAnimTint'] = getColorAnimTint

export const getDefaultCamera = () => {
    return new THREE.PerspectiveCamera(75, 1, 0.1, 10000)
}


export interface WSViewerOptions {
    root: HTMLElement
    url?: string
}

export type WSElementID = number;

export interface WSViewerRenderable {
    readonly mesh: THREE.InstancedMesh;
    readonly index: number;
    readonly bbox: THREE.Box3;
}

export interface AnimationKeyframe {
    polar: number;
    distance: number;
    azimuthal: number;
    target: THREE.Vector3;
}

export type XYZ = {
    x: number,
    y: number,
    z: number
}

export type ViewerOrbitControls = {
    position: XYZ
    target: XYZ
}

export type Ren = ViewerOrbitControls & {
    width: number
    height: number

}

const colorsByHex: Map<HEX, THREE.Color> =   new Map<HEX, THREE.Color>();

export const getColorByHex = (hex: HEX): THREE.Color => {
    if(!colorsByHex.has(hex))
        colorsByHex.set(hex, new THREE.Color(hex))

    return colorsByHex.get(hex)
}

const materialsByHex: Map<HEX, THREE.MeshStandardMaterial> =   new Map<HEX, THREE.MeshStandardMaterial>();
type HexNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type HexString = 'a' | 'b' | 'c' | 'd' | 'e' | 'f'
type HexStrNum = string
type HexSign = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f'
type StringNumber<T extends number> = `${T}`
type SemiHex = `${HexStrNum}${HexStrNum}${HexStrNum}`
export type HEX = `#${SemiHex}${SemiHex}`

type TruncateTo<T extends string, N extends number,
    L extends any[] = [], A extends string = ""> =
    N extends L['length'] ? A :
        T extends `${infer F}${infer R}` ? (
                TruncateTo<R, N, [0, ...L], `${A}${F}`>
                ) :
            A

export const getMaterialByHex = (hex: HEX): THREE.MeshStandardMaterial => {
    if(!materialsByHex.has(hex)) {
        const material = new THREE.MeshStandardMaterial({color: getColorByHex(hex)})

        materialsByHex.set(hex, material)
    }
    return materialsByHex.get(hex)
}

export interface WSAnimation {
    enable: boolean;
    startTime: number;
    start: AnimationKeyframe;
    end: AnimationKeyframe;
}

export const defaultMaterial: THREE.MeshStandardMaterial = new THREE.MeshStandardMaterial();
export const defaultColorHex = '#A5A5A5'

export const addLights = (scene: THREE.Scene) => {

    scene.add(new THREE.AmbientLight(0xffffff, 0.5));

    const dirLight1 = new THREE.DirectionalLight(0xddddff, 0.5);
    dirLight1.position.set(1, 4, 2);
    scene.add(dirLight1);

    const dirLight2 = new THREE.DirectionalLight(0xffffdd, 0.25);
    dirLight2.position.set(-1, -4, -2);
    scene.add(dirLight2);
}
