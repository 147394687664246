import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import  {
  ProjectVO,
} from "iso/src/store/bootstrap/repos/projectsCURD";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {Props as LabelProps} from "recharts/types/component/Label";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";

import "./chart.scss";
import {calculateMinWidthContainer, percentFormatter, Rect, Sector, toPercent} from "./common";

const sectorStatuses = {
  bottom: "Принято стройконтролем",
  center: "Окно смонтировано",
  top: "Каркас изготовлен",
};

const noStatStatusId = [1,101,109]
export default ({projects}: { projects: ProjectVO[] }) => {
  const statuses = useFrontSelector(settingsDuck.selectStatuses);

  const getLabelColor = (status: string) =>
    statuses.find(({name}) => name === status)?.color;
  const getColumnLabelColor = (sector: Sector) =>
    getLabelColor(sectorStatuses[sector]);

  //TODO в модели статуса на на что завязаться, кроме как на name (на русском). Думаю было бы лучше в модели использовать
  // что-то вроде {name: 'mounted', label: 'Окно смонтировано'}
  const data = projects?.map((p) => {
    const item = {
      name: p.analyticsName || p.name,
      ["Принято стройконтролем"]: 0,
      ["Окно смонтировано"]: 0,
      ["Каркас изготовлен"]: 0,
    };
    p.stones.forEach((s) => {
      item["Каркас изготовлен"] += (s.statusId === 105  ||s.statusId === 106 ||s.statusId === 107 ) ? 1 : 0;
      item["Окно смонтировано"] += (s.statusId === 108) ? 1 : 0;
      item["Принято стройконтролем"] += (s.statusId === 111 || s.statusId === 110) ? 1 : 0;
    });
    const isNoStatStatusId = (statusId: number) => {
      return noStatStatusId.includes(statusId)
    }
    const length =p.stones.filter(s => !isNoStatStatusId(s.statusId)).length
    item["Окно смонтировано"] = toPercent(length, item["Окно смонтировано"]);
    item["Принято стройконтролем"] = toPercent(length, item["Принято стройконтролем"]);
    item["Каркас изготовлен"] = toPercent(length, item["Каркас изготовлен"]);
    return item;
  });

  let rects: Record<string, Record<Sector, Rect>> = {};
  const saveRect = (sector: Sector) => (rect: Rect, name) => {
    if (!rects[name]) rects[name] = {};
    rects[name][sector] = rect;
  };

  const renderCustomizedLabels = (props: LabelProps) => {
    const proj = data.find((p) => p.name === props.name);
    const {x, y, width, value, height} = props;
    console.log(props, proj);

    const labelHeight = 20;
    const labelPadding = (width - 80) / 2;

    let bottomRect;
    let centerRect;
    let topRect;
    const renderLabel = (sector: Sector) => {
      const rect = rects[proj.name][sector];
      const value =
        sector === "bottom"
          ? proj["Принято стройконтролем"]
          : sector === "center"
            ? proj["Принято стройконтролем"] + proj["Окно смонтировано"]
            : proj["Каркас изготовлен"] +
            proj["Окно смонтировано"] +
            proj["Принято стройконтролем"];
      const display = percentFormatter(value);
      const {x, y, width, height} = rect;
      const actualHeight = height > labelHeight ? labelHeight : labelHeight;
      console.log("render " + sector, rect);

      let inner = {
        x: x + labelPadding,
        y: y + height / 2 - actualHeight / 2,
        width: width - labelPadding * 2,
        height: actualHeight,
      };

      if (sector === "bottom") {
        if (inner.height > height) {
          inner.y = y - inner.height;
        }
        bottomRect = inner;
      }
      if (sector === "center") {
        if (inner.y + inner.height > y + height) {
          inner.y = y + height - inner.height;
        }
        if (inner.height + inner.y > bottomRect.y) {
          inner.y = bottomRect.y - inner.height;
        }
        centerRect = inner;
      }
      if (sector === "top") {
        if (inner.y + inner.height > y + height) {
          inner.y = y + height - inner.height;
        }
        if (inner.height + inner.y > centerRect.y) {
          inner.y = centerRect.y - inner.height;
        }
        topRect = inner;
      }
      return (
        <g>
          <rect {...inner} fill={getColumnLabelColor(sector)} />
          <text
            x={Number(x) + Number(width) / 2}
            y={Number(inner.y) + Number(inner.height) / 2 + 2}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            filter='drop-shadow(1px 1px 0.5px black)'
          >
            {display}
          </text>
        </g>
      );
    };

    return [renderLabel("bottom"), renderLabel("center"), renderLabel("top")];
  };
  return (
    <div style={{width: "100%", height: "80%"}}>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        <ResponsiveContainer
          width="100%"
          height="97%"
          minWidth={`${calculateMinWidthContainer(data?.length)}px`}
        >
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 50,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis
              yAxisId="left"
              type="number"
              domain={[0, 100]}
              max={100}
              unit={"%"}
            />
            <YAxis
              yAxisId="right"
              type="number"
              orientation="right"
              domain={[0, 100]}
              max={100}
              unit={"%"}
            />
            {
              // <Tooltip formatter={percentFormatter} />
            }
            <Legend
              style={{paddingTop: "60px"}}
              iconType={"circle"}
              iconSize={24}
            />

            <Bar
              yAxisId="left"
              dataKey="Принято стройконтролем"
              stackId="a"
              fill={getLabelColor("Принято стройконтролем")}
            >
              <LabelList
                content={({x, y, width, height, name}) =>
                  saveRect("bottom")({x, y, width, height}, name)
                }
                dataKey={"Принято стройконтролем"}
                stroke={"black"}
                position="bottom"
                formatter={percentFormatter}
              />
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="Окно смонтировано"
              stackId="a"
              fill={getLabelColor("Окно смонтировано")}
            >
              <LabelList
                content={({x, y, width, height, name}) =>
                  saveRect("center")({x, y, width, height}, name)
                }
                dataKey={"Окно смонтировано"}
                color={"black"}
                position="center"
                formatter={percentFormatter}
              />
            </Bar>
            <Bar
              yAxisId="left"
              dataKey="Каркас изготовлен"
              stackId="a"
              fill={getLabelColor("Каркас изготовлен")}
            >
              <LabelList
                content={({x, y, width, height, name}) =>
                  saveRect("top")({x, y, width, height}, name)
                }
                dataKey={"Каркас изготовлен"}
                color={"black"}
                position="top"
                formatter={percentFormatter}
              />
              <LabelList
                content={renderCustomizedLabels}
                dataKey={"Окно смонтировано"}
                color={"black"}
                position="bottom"
                formatter={percentFormatter}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
