import React from 'react'
import {routeBuilder} from '@sha/router'
import LoginPage from "./auth/LoginPage";
import AccessPage from "./access/AccessPage";
import StatsScreen from "./stats/StatsScreen";
import ProjectCommentsScreen from "./comments/ProjectCommentsScreen";
import StoneCardScreen from "./comments/StoneCardScreen";
import TutorialPage from "./tutorial/TutorialPage";
import SettingsScreen from "./settings/SettingsScreen";
import ExampleCardPage from "./examples/ExampleCardPage";
//import {RoutedScreen} from "./RoutedScreen";
import ProjectPage from "./ProjectPage";
import {RouteScreen} from "./RoutedScreen";
import WSIonRoute from "./controls/WSIonRoute";
import ExampleListPage from "./examples/ExampleListPage";
import ExampleGridPage from "./examples/ExampleGridPage";
import ExampleDialogModal from "./examples/ExampleDialogModal";
import ExampleSheetModal from "./examples/ExampleSheetModal";

import AgendaScreen from "./agenda/AgendaScreen";
import ChartsPage from './charts/ChartsPage'

export * from '@sha/router'

const buildNav = <Path extends string, Comp extends RouteScreen<Path>>
(path: Path, Component: Comp) => {
    const builder = routeBuilder(path)
    return Object.assign(builder, {
        Component
    })
}

export const nav = {
    exampleCard: buildNav('/app/in/examples/card', ExampleCardPage),
    exampleList: buildNav('/app/in/examples/list', ExampleListPage),

    exampleGrid: buildNav('/app/in/examples/grid', ExampleGridPage),
    exampleДаилог: buildNav('/app/in/examples/dialog', ExampleDialogModal),
    exampleSheet: buildNav('/app/in/examples/sheet', ExampleSheetModal),
    login: buildNav('/app/login', LoginPage),
    access: buildNav('/app/in/projects', AccessPage),
    charts: buildNav('/app/in/charts/:projectGroup',ChartsPage),
    project: buildNav('/app/in/projects/:projectId', ProjectPage),

    stats: buildNav('/app/in/projects/:projectId/stats', StatsScreen),

    comments: buildNav('/app/in/projects/:projectId/comments', ProjectCommentsScreen),
    stoneCard: buildNav('/app/in/projects/:projectId/stone/:id', StoneCardScreen),
    agenda: buildNav('/app/in/project/:projectId/agenda',AgendaScreen),

    // history: buildNav('/app/in/projects/:projectId/history'),
    tutorial: buildNav('/app/in/projects/:projectId/tutorial', TutorialPage),
    settings: buildNav('/app/in/projects/:projectId/settings', SettingsScreen)
}


export const rootRoutes = Object.values(nav)
    .map(({Component, pattern}) => (
        <WSIonRoute
            exact={true}
            key={pattern}
            path={pattern}
            render={routeProps => {
                // @ts-ignore
                return (
                    <Component {...routeProps} {...routeProps.match.params} />
                )
            }}
        />
    ))
