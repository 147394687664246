import {
    IonAccordionGroup,
    IonCard,
    IonCardContent,
    IonContent, IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText
} from "@ionic/react";
import LinkedStatusesList from "../../list-statuses/LinkedStatusesList";
import React, {useState} from "react";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";
import {Icons} from "../controls/Icons";
import useRole from "../../../hooks/useRole";

export const ClosedToleDescriptionList = ({roleId}) => {
    const role = useFrontSelector(settingsDuck.selectSettings).roles.find(r => r.roleId === roleId)
    const [expanded, setExpanded] = useState(false)
    return expanded
        ? <RoleDescriptionList roleId={role.roleId}/>
        : <IonItem button  color={'primary'} onClick={e=>{
            console.log('setopen')
            setExpanded(true)
        }}>
            <IonLabel>Описание роли</IonLabel>
            <IonIcon slot="end" icon={Icons.informationCircleOutline}/>
        </IonItem>


}

export const RoleDescriptionList = ({roleId}) => {
    const role = useFrontSelector(settingsDuck.selectSettings).roles.find(r => r.roleId === roleId)

    console.log('Role', role)
    return <>

            <IonListHeader>
                <h4><b>{role.name}:</b> {role.description}</h4>
            </IonListHeader>
        <IonListHeader>
                <h4>Может назначать статусы:</h4>
            </IonListHeader>

                <IonAccordionGroup>
                    {role.destinationStatusesIds.map(nextStatusId =>
                        <LinkedStatusesList key={nextStatusId} nextStatusId={nextStatusId} useCard={true}/>
                    )}
                </IonAccordionGroup>

    </>

}
/*
export default () => {
    const {currentRole} = useRole()
     return <IonPage>
        <IonContent>
        <RoleDescriptionList roleId={currentRole.roleId}/>

        </IonContent>
    </IonPage>
}
*/
