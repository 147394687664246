import {IonItem, IonLabel} from '@ionic/react'
import WSSelect from './WSSelect'
import React from 'react'
import projectsCURD, {ProjectVO} from 'iso/src/store/bootstrap/repos/projectsCURD'
import useFrontSelector from '../../../hooks/common/useFrontSelector'

export default ({projectId, onProjectChange, hideFixedLabel}) => {
    const projects: ProjectVO[] = useFrontSelector(projectsCURD.selectList).reverse()
   return  <IonItem lines={'full'}>

       {!hideFixedLabel && <IonLabel position={"fixed"}>Проект</IonLabel>}
        <WSSelect

            type={'alert'}
            label={undefined}
            placeholder={'Выберите проект'}
            value={projectId}
            onValueChange={onProjectChange}
            options={projects.map(r => ({
                value: r.projectId,
                label: r.name,
            }))}
        />
    </IonItem>
}