import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {usersCRUD} from "iso/src/store/bootstrap";
import {IonAvatar} from "@ionic/react";
import React from "react";
import {ExtractProps} from "@sha/react-fp";

export default ({userId, ...props}: { userId } & ExtractProps<HTMLIonAvatarElement>) => {
    const url = useFrontSelector(usersCRUD.selectAvatar(userId))

    return <IonAvatar slot={'start'}>
        <img src={url}/>
    </IonAvatar>
}
