import React, {useState} from 'react';
import {IonButton, IonIcon, useIonAlert} from '@ionic/react';
import {useDispatch} from "react-redux";
import {uiDuck} from "../../../store/ducks/uiDuck";
import {logOutOutline} from "ionicons/icons";
import {routerDuck} from '../../../store/ducks/routerDuck'
import {nav} from '../nav'

function Example({onExit}) {
    const dispatch = useDispatch()
    const [presentAlert,] = useIonAlert();
    const [handlerMessage, setHandlerMessage] = useState('');
    const [roleMessage, setRoleMessage] = useState('');
    const onDidDismiss = (role: string) => {
        if (role === 'confirm')
            onConfirmExit()
    }
    const onConfirmExit = async () => {

        dispatch(uiDuck.actions.logout(undefined))
        dispatch(routerDuck.actions.replacePage(nav.login({})))
       if(onExit) onExit()
    }
    return (

        <IonButton

            onClick={() => presentAlert({
            header: 'Выйти из аккаунта?',
            buttons: [
                {
                    text: 'Нет',
                    role: 'cancel',
                    handler: () => {
                        setHandlerMessage('Alert canceled');
                    }
                },
                {
                    text: 'Да',
                    role: 'confirm',
                    handler: () => {
                        setHandlerMessage('Alert confirmed');
                    }
                }
            ],
            onDidDismiss: (e: CustomEvent) => onDidDismiss(e.detail.role)
        })}>
            <IonIcon icon={logOutOutline} style={{transform: 'rotate(180deg)'}}></IonIcon>
        </IonButton>

    );
}

export default Example;
