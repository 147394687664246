import React, {useCallback, useState} from 'react'
import * as Icons from 'ionicons/icons';
import StatusSelector from "../../list-statuses/StatusSelector";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import {useDispatch} from "react-redux";
import {
    IonFooter,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    IonToolbar, useIonViewDidEnter
} from "@ionic/react";
import {useProject} from "../../../hooks/useProject";
import useStatuses from "../../../hooks/useStatuses";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useStones from "../../../hooks/useStones";
import useViewer from "./tools/useViewer";
import useInteractiveStones from "./tools/useInteractiveStones";
import {equals, reject} from "ramda";
import StatusItem from "../../list-statuses/StatusItem";
import useProjectStatuses from '../../../hooks/useProjectStatuses'
import {projectsCRUD} from 'iso/src/store/bootstrap'
import {useMount} from 'react-use'


const ToolBrush = (/*{selectedStoneIds}:{selectedStoneIds: string[]}*/) => {
    const dispatch = useDispatch()
    const viewer = useViewer()

    useMount( () => window.destinationStatusId = undefined)
    ///console.log('ToolSingle', viewer)
    const project = useProject()
    const {projectId} = project

    const {projectStatuses} = useProjectStatuses(projectId)
    const {getStatusById} = useStatuses()
    const {currentRole} = useCurrentUser()

    const [destinationStatusId, setDestinationStatusId] = useState(undefined)
    //console.log('ToolBrash:logvars - destinationStatusId',destinationStatusId)
    const getStone = stoneId =>
        project.stones[stoneId]

    const onStoneClick = (id) => {
        const destinationStatusId = window.destinationStatusId
        const state= window.store.getState()
        const selectedStoneIds = state.ui.selection.ids
        const getStoneById = (stoneId) => {
            const state = window.store.getState()
            const proj = projectsCRUD.selectById(project.projectId)(state)
            const stone = proj.stones.find(s => s.id === stoneId)

            return stone
        }
        if(id === undefined)
            return;
        if(selectedStoneIds.includes(id)) {
            console.log('deselect stone '+id)
            setSelectedStoneIds(reject(equals(id), selectedStoneIds))
            return
        }
        const stone = getStoneById(id)
        const stoneStatus = getStatusById(stone.statusId)
        console.log(stone,stoneStatus,'to',destinationStatusId)
        if(!stoneStatus.nextStatusesIds.includes(destinationStatusId)) {
            console.log('Вы не можете назначить этому элементу используемый вами статус')
            return
        }


        if(destinationStatusId)
            updateStatuses(destinationStatusId, [id])

        setSelectedStoneIds([id])
    }
    const [selectedStoneIds, setSelectedStoneIds] = useInteractiveStones({onStoneClick})

    const {updateStatuses} = useStones(selectedStoneIds)
/*
    useIonViewDidEnter(() =>{
        console.log('ToolBrushDidEnter')
        if(window['focus']) {
            const ids = window['focus']
            window['focus'] = undefined
            dispatch(uiDuck.actions.focusToList(ids))
            setSelectedStoneIds(ids)
        }
    }, [])**/


    const onStatusChange = nextStatusId => {
        window.destinationStatusId = nextStatusId
        setDestinationStatusId(nextStatusId)
        console.log('setDestinationStatusIs to '+nextStatusId)

    }

    const availableStatusIds = currentRole.destinationStatusesIds.filter(statusId => projectStatuses.find(s => s.statusId === statusId))



    return <IonFooter  translucent={true}>
        <IonPopover size={'cover'}  keepContentsMounted={true} side={'top'} trigger="change-status-popover" dismissOnSelect={true}>

            <IonList>
                {
                    availableStatusIds.length
                        ?availableStatusIds.map(nextStatusId =>
                            <StatusItem lines={'none'}
                                        selected={nextStatusId === destinationStatusId}
                                        statusId={nextStatusId}
                                        onClick={ () => {
                                            onStatusChange(nextStatusId)
                                        }}
                            >

                            </StatusItem>
                        )
                        : <IonItem color={'medium'} lines={'none'} >
                            <IonLabel>Нет доступных статусов</IonLabel>
                        </IonItem>

                }

            </IonList>

        </IonPopover>
        <IonToolbar       id={'change-status-popover'}>

            {
                destinationStatusId === undefined
                    ?   <IonItem
                            button
                            lines={'none'}
                        >
                            <IonLabel>Выберите целевой статус</IonLabel>
                        </IonItem>
                    : <StatusItem
                        button

                        statusId={
                            destinationStatusId
                        }
                        lines={'none'}
                    >

                    </StatusItem>
            }
        </IonToolbar>
    </IonFooter>


}

export default ToolBrush
