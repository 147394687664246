import React from 'react'
import useFrontDispatch from "../../../hooks/common/useFrontDispatch"
import useFrontSelector from "../../../hooks/common/useFrontSelector"

import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD";
import {
    IonBadge,
    IonButtons,
    IonContent, IonDatetime, IonFooter,
    IonHeader, IonLabel,
    IonList,
    IonPage,
    IonSegment, IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'

import settingsDuck from "iso/src/store/bootstrap/settingsDuck"
import {useProject} from "../../../hooks/useProject";
import BackToProjectButton from "../controls/BackToProjectButton";
import StatusItem from "../../list-statuses/StatusItem";
import {StoneStatus} from "iso/src/store/bootstrap/StatusesMatrix";
import useProjectStatuses from '../../../hooks/useProjectStatuses'
import useStatuses from '../../../hooks/useStatuses'
import useStones from '../../../hooks/useStones'

function swapElements(arr, i1, i2) {
    // Step 1
    let temp = arr[i1];

    // Step 2
    arr[i1] = arr[i2];

    // Step 3
    arr[i2] = temp;
}

const ComposedBars = ({projectId}) => {
    const dispatch = useFrontDispatch()

    const {isNoStatStatusId} = useStones()
    const project = useFrontSelector(projectsCURD.selectById(projectId))
    const stones = project.stones

    const {projectStatuses} = useProjectStatuses(project.projectId)
    const statuses =projectStatuses.filter(s => !isNoStatStatusId(s.statusId))
    const arr = [...statuses]

    const getStatus = statusId => statuses.find(s => s.statusId === statusId)
    const totalLength = stones.filter(s => !isNoStatStatusId(s.statusId)).length
    const stonesBatchesByStatuses = useFrontSelector(projectsCURD.selectStonesGroupedByStatuses(project.projectId))
    const getPercent = statusId => {
        try {
            const stonesWithStatus = stonesBatchesByStatuses.find(s => s.statusId === statusId)
            const part = (stonesWithStatus.stones.length / totalLength * 100)
            if(statusId === 111)
                debugger
            return part
        } catch (e) {
            debugger
            return 0
        }
    }
    const getValue = statusId => {
        // const stonesWithStatus = stonesBatchesByStatuses.find(s => s.statusId === statusId)
        const stonesWithStatus = stonesBatchesByStatuses.find(s => s.statusId === statusId)
        const part = Number((stonesWithStatus.stones.length / totalLength).toPrecision(1))
        return part
    }
    const getPercentBarWidth = (statusId) => {
        let p = getPercent(statusId)
        if (p === 0) {
            return 0
        }

        return Math.max(getPercent(statusId), 1)
    }
    const formatPercentText = n => {
        return (n < 0
            ? '0' + n.toFixed(2)
            : n < 10
                ? '0' + n.toFixed(2)
                : n.toFixed(2)) + '%'
    }

    const progresses = []
    const getProgressList = (hostStatus: StoneStatus) => {

        const list: Part[] = []
        list.push({color: hostStatus.color, percent: getPercent(hostStatus.statusId), ...hostStatus })

        hostStatus.includedStatusIds.filter(id=> id !== null).forEach(id => {
            const subStatus = getStatus(id)
            if(!subStatus)
                debugger
            list.push({color: subStatus.color, percent: getPercent(id), ...subStatus})
        })

        return list
    }
    return [
        <IonContent>
                <IonList>
                    {
                        arr.map((s, i) => {

                                const list = getProgressList(s)
                                const currProgress = getPercent(s.statusId)
                                const totalProgress = list.reduce((res, c)=>res+c.percent,0)
                                console.log('Status progress ',s.name,list,currProgress,totalProgress)
                                return <StatusItem currentPrecent={currProgress} statusId={s.statusId} progressBar={  <ProgressBar list={list}  totalProgress={totalProgress}/>} className={'ion-activateable'}>
                                    <IonBadge color={s.color}>
                                        {formatPercentText(totalProgress)
                                        }</IonBadge>

                                </StatusItem>

                            }
                        )
                    }


                </IonList>

</IonContent>,
   /* <IonFooter>
        <IonToolbar>
            <IonDatetime presentation="date" preferWheel={true}  ></IonDatetime>
        </IonToolbar>
    </IonFooter>*/
    ]
}

type Part = StoneStatus & {
    percent: number
}
const ProgressBar = ({list}:{list: Part[]}) => {
    let dx = 0
    return <svg style={{width: '100%'}} viewBox={'0,0,100,3'}>
        <rect x={0} y={0} width={100} height={3} stroke="grey" stroke-dasharray="0.5"  fill={'none'} strokeWidth={0.1}></rect>
        {
            list.map( (p,i) => {
                var d = dx
                dx+=p.percent
                return <rect x={d} y={0} width={p.percent} fillOpacity={i === 0 ? '1' : '0.4'} height={3} stroke={'none'} fill={p.color}></rect>
            })
        }
    </svg>
}

export default ComposedBars;
