import React from 'react'
import {IonAlert, IonToast} from "@ionic/react";
import {useSelector} from "react-redux";
import sseConnectionDuck from "iso/src/store/sse/sseConnectionDuck";
import * as IonIcons  from 'ionicons/icons';
export default () => {
    const state = useSelector(sseConnectionDuck.select)
    return  <>
        <IonToast
            isOpen={state.pushingEvents.length > 0}
            message={"Запись статусов "+state.pushingEvents.length}

                position={"top"}
            icon={IonIcons.radio}
        />
        <IonAlert
            isOpen={state.failedPushes.length>0}
            header="Ошибка"
            subHeader="Не удалось отправить запрос"
            message="Попробуйте перезапустить приложение"
            buttons={['OK']}
        />
    </>
}