import ViewerContext from "./ViewerContext";
import {useProject} from "../../../../hooks/useProject";
import {ReadyViewer} from "../WSReactViewer";
import usePrefKey from "../../../../hooks/common/usePrefKey";
import {useKeyPress} from "react-use";
import {Key} from "ts-keycode-enum";
import {useKeyPressed} from "../../../../hooks/common/useKeyPressed";
import {ViewerOrbitControls} from "scene/src/utils";


export default () => {
    const api = ViewerContext.subscribe()

    const [animatedColors] = usePrefKey('animatedColors')
    let viewer: typeof api['viewer']
    let divRoot
    let canvas
    const project = useProject()
    if (api) {
        viewer = api.viewer
        if(viewer)
            viewer.animatedColors = animatedColors
        divRoot = api.divRoot
        canvas = divRoot.children[0] as any as HTMLCanvasElement
    }


    const getEventCoords = e => {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;
        return {x, y}
    }

    const getStoneIDByEvent = e => {
        const {x, y} = getEventCoords(e)
        const numberId = viewer.raycast(x, y);
        const stringId = numberId !== null ? String(numberId) : undefined
        return stringId
    }

    const getStoneByEvent = e => {
        const stoneId = getStoneByEvent(e)
        return stoneId ? project.stones.find(s => s.id === stoneId) : undefined
    }


    return {...{viewer, divRoot} as ReadyViewer, canvas, getEventCoords, getStoneIDByEvent, getStoneByEvent}
}
