import React from 'react';
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import {ProjectVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import {
    IonButtons,
    IonCard,
    IonCardContent,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar
} from '@ionic/react';
import {useHistory} from "react-router"
import * as Icons from "ionicons/icons"

import {useProject} from "../../../hooks/useProject";
import BackToProjectButton from "../controls/BackToProjectButton";
import usePreferences, {PrefKey} from "../../../hooks/common/usePreferences";
import usePrefKey from "../../../hooks/common/usePrefKey";

const SettingsOption = ({prefKey, name, icon}: {name: string, icon, isAdminOnly?: boolean, prefKey: PrefKey}) => {

    const [value, setValue] = usePrefKey(prefKey)
    return <IonItem>
        {icon && <IonIcon slot={'start'} icon={icon}></IonIcon>}

        <IonLabel>
            {name}
        </IonLabel>
        <IonToggle slot={'end'} checked={Boolean(value)}
                   onIonChange={e => setValue(e.detail.checked)}/>

    </IonItem>
}

const SettingsScreen = (props) => {
    return (<IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <BackToProjectButton/>
                    </IonButtons>
                    <IonTitle>Настройки</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <SettingsOption name={'Тёмная версия'} icon={Icons.moon} prefKey={'darkMode'}/>
                    <SettingsOption name={'MaterialUI тема'} icon={Icons.balloon} prefKey={'ionModeMaterial'}/>
                    <SettingsOption name={'Анимация элементов'} icon={Icons.flashlight} prefKey={'animatedColors'}/>
                </IonCard>
                <IonCard>
                    <SettingsOption name={'Показывать уведомлени'} icon={Icons.radio} prefKey={'toastsEventsFeed'}/>
                    <IonCardContent>
                        На экране просмотра блоков происходит незамедлительное информирование о событияш произошедхиш в
                        проекте
                    </IonCardContent>
                </IonCard>
                <IonCard> <SettingsOption name={'Выводить FPS'} icon={Icons.skull} prefKey={'showFPS'}/>
                    <SettingsOption name={'Тест вёрстки'} icon={Icons.skull} prefKey={'showExamples'}/>
                    <IonCardContent>
                        Настройки разработчика
                    </IonCardContent>
                </IonCard>

            </IonContent>
            <IonFooter>
                <IonToolbar>

                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

export default SettingsScreen;
