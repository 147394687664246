import React from 'react';
import CommentAddBox from "./CommentAddBox";
import useFrontDispatch from "../../../hooks/common/useFrontDispatch";
import useFrontSelector from "../../../hooks/common/useFrontSelector";
import projectsCURD, {ProjectVO} from "iso/src/store/bootstrap/repos/projectsCURD";
import {
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonNote,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useHistory} from "react-router"
import * as Icons from "ionicons/icons"
import {generateGuid} from "@sha/random"
import CommentView from "./CommentItem";
import {useProject} from "../../../hooks/useProject";
import moment from "moment";
import EmptyView from "../controls/EmptyView";
import {usersCRUD} from "iso/src/store/bootstrap";
import BackToProjectButton from "../controls/BackToProjectButton";
import settingsDuck from "iso/src/store/bootstrap/settingsDuck";


const StoneCardScreen = (props) => {


    const project: ProjectVO = useProject()
    const id = props.match.params.id
    const projectId = project.projectId
    const history = useHistory()
    const dispatch = useFrontDispatch()

    const onPostComment = async (text) => {
        const action = projectsCURD.actions.commentAdded({
            text, id, projectId, commentId: generateGuid(),
            userId: user.userId
        })
        dispatch(action)
    }


    const users = useFrontSelector(usersCRUD.selectAll)
    if (!project)
        return null
    const stone = project.stones.find(s => s.id === id)
    const comments = id ? project.comments.filter(c => String(c.id) === String(id)) : project.comments
    const statuses = useFrontSelector(settingsDuck.selectStatuses)
    const status = useFrontSelector(settingsDuck.selectStatusById(stone.statusId))
    const user = users.find(u => u.userId === stone.userId)
    const name = user ? user.firstName + ' ' + user.lastName : 'Не установлен'
    return (<IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <BackToProjectButton/>
                    </IonButtons>
                    <IonTitle>Элемент {stone.name}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>


                    <IonItem button>
                        <IonLabel>
                            Статус
                        </IonLabel>
                        <IonNote slot="end">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <IonLabel>{status.name}</IonLabel>
                                <IonIcon size={'small'} icon={Icons.ellipse}
                                         style={{color: status.color, marginLeft: '8px'}}></IonIcon>
                            </div>
                        </IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            Время
                        </IonLabel>
                        <IonNote
                            slot="end">{stone.updatedAt ? moment(stone.updatedAt).format('YYYY/MM/DD hh:mm') : 'не обновлялся'}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            Автор
                        </IonLabel>
                        <IonNote slot="end">{name}</IonNote>
                    </IonItem>
                    <IonListHeader lines="inset">
                        <IonLabel>Комментарии</IonLabel>
                    </IonListHeader>
                    {
                        comments.length ? comments.map(c => <CommentView
                                comment={c} key={c.commentId}/>
                            )
                            : <EmptyView description={'Список пуст'}/>
                    }
                </IonList>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <CommentAddBox onPost={onPostComment} stoneId={id}/>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

export default StoneCardScreen;
