import React, {useState} from 'react'
import useFrontDispatch from "../../../hooks/common/useFrontDispatch"
import useFrontSelector from "../../../hooks/common/useFrontSelector"

import projectsCURD from "iso/src/store/bootstrap/repos/projectsCURD";
import {
    IonBadge,
    IonButtons,
    IonContent, IonDatetime, IonFooter,
    IonHeader, IonLabel,
    IonList,
    IonPage,
    IonSegment, IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'

import settingsDuck from "iso/src/store/bootstrap/settingsDuck"
import {useProject} from "../../../hooks/useProject";
import BackToProjectButton from "../controls/BackToProjectButton";
import StatusItem from "../../list-statuses/StatusItem";
import {StoneStatus} from "iso/src/store/bootstrap/StatusesMatrix";
import useProjectStatuses from '../../../hooks/useProjectStatuses'
import useStatuses from '../../../hooks/useStatuses'
import useStones from '../../../hooks/useStones'
import ComposedBars from './ComposedBars'

function swapElements(arr, i1, i2) {
    // Step 1
    let temp = arr[i1];

    // Step 2
    arr[i1] = arr[i2];

    // Step 3
    arr[i2] = temp;
}

const StatsScreen = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const project = useProject()

    return (<IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <BackToProjectButton/>
                    </IonButtons>
                    <IonTitle>Итоги</IonTitle>
                    {
                  /*
                    <IonSegment value="default">

                        <IonSegmentButton  value="default">
                            <IonLabel >Сегодняы</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="segment">
                            <IonLabel>История</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>


                   */
                    }
                </IonToolbar>
            </IonHeader>
                {
                    selectedTab === 0
                        ? <ComposedBars projectId={project.projectId}></ComposedBars>
                        : <ComposedBars projectId={project.projectId}></ComposedBars>
                }
        </IonPage>
    )
}

type Part = StoneStatus & {
    percent: number
}
const ProgressBar = ({list}:{list: Part[]}) => {
    let dx = 0
    return <svg style={{width: '100%'}} viewBox={'0,0,100,3'}>
        <rect x={0} y={0} width={100} height={3} stroke="grey" stroke-dasharray="0.5"  fill={'none'} strokeWidth={0.1}></rect>
        {
            list.map( (p,i) => {
                var d = dx
                dx+=p.percent
                return <rect x={d} y={0} width={p.percent} fillOpacity={i === 0 ? '1' : '0.4'} height={3} stroke={'none'} fill={p.color}></rect>
            })
        }
    </svg>
}

export default StatsScreen;
